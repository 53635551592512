<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
      </div>
      <div class="section">
        <div class="top">
          <div class="title"># {{$route.query.name}}</div>
          <p class="des">{{tagInfo.watches|watchCount}}次播放</p>
        </div>
        <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="videoList.length>0">
          <div class="bottom flex-between">
            <videoList :item="item" v-for="item in videoList" :key="item.id"/>
          </div>
        </pullrefresh>
        <van-loading type="spinner" color="#f21313" v-show="videoList&&videoList.length==0 && !noData"/>
        <nodata v-show="noData"/>
      </div>
    </div>
  </div>
</template>
<script>
import { optRecord } from "@/utils/optLog.js";
import videoList from '../widget/videoList.vue';
import pullrefresh from "@/components/PullRefresh/index.vue";
import {getTagsMedia} from '@/api/app.js';
export default {
  components:{
    videoList,
    pullrefresh
  },
  data(){
    return{
      pageNum:1,//页码
      videoList:[],//视频列表
      tagInfo:{},//标签信息
      noData:false,//暂无数据
    }
  },
  created(){
    optRecord(2, undefined, this.$route.query.name);
    this.getTagsMedia();
  },
  methods:{
    async getTagsMedia(){
      let req = {
        bType:1,
        mType:2,
        pageNum:this.pageNum,
        pageSize:10,
        tag:this.$route.query.name
      }
      let ret = await this.$Api(getTagsMedia,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        this.tagInfo = ret.data.tagInfo;
        let list = ret.data.mediaInfos?ret.data.mediaInfos:[]
        this.videoList = this.videoList.concat(list);
        if(list.length < 10){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    onLoad(){
      this.pageNum ++;
      this.getTagsMedia();
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.title{
  font-size:18px;
  font-weight:bold;
}
.des{
  margin-top:5px;
  font-size:10px;
  color:#515151;
  margin-bottom:10px;
}
.bottom{
  padding-bottom:30px;
  flex-wrap: wrap;
}
</style>
